<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import LoadSections from "@/mixins/loadSections";

export default {
  name: "ThankYouReview",

  extends: View,

  mixins: [
    LoadSections([
      "layout-header-menu",
      "public-thank-you-review",
      "layout-footer-basic",
    ]),
  ],
};
</script>
